import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoomIdService } from './room-id.service';
import { UserService } from '../user/user.service';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { EstimateService } from './estimate.service';
import { ResultsComponent } from './results.component';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { ResultsDialog } from './results.dialog';
import { environment } from '../../environments/environment';
import { AdvertisingService } from '../common/advertising.service';
//declare const window: any;
declare global {
    interface Window {
        fusetag: any;
    }
}

@Component({
    templateUrl: './poker-room.page.html',
    styleUrls: ['./poker-room.page.scss'],
})

export class PokerRoomPage implements OnInit {
    public delayAdsense = false;
    public isLoading = true;
    isExistentRoom = true;
    public environment = environment;
    constructor(
        private route: ActivatedRoute,
        public roomIdService: RoomIdService,
        public userService: UserService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public estimateService: EstimateService,
        private googleAnalyticsService: GoogleAnalyticsService,
        public dialog: MatDialog,
        public advertisingService: AdvertisingService
    ) {
    }

    async ngOnInit() {
        try {

            this.roomIdService.setRoomId(this.route);
            this.isExistentRoom = await this.estimateService.isExistentRoom(this.roomIdService.getCurrentRoomID());
            this.logger.log('ngOnInit ownerUid', this.estimateService.ownerUid)
            this.isLoading = false;
            this.logger.log('PokerRoomPage:ngOnInit');
            this.route.params.subscribe(params => {
                this.delayAdsense = true;
                setTimeout(() => (
                    this.delayAdsense = false
                ), 200);
            });
            this.initFuse()
            this.initPrimis()
        } catch (error) {
            this.uiMessage.error(error, 'Initializing the room. Please check your internet connection.');
        }
    }

    private initFuse() {
        setTimeout(() => {
            this.logger.log("initFuse 1000");
            this.advertisingService.registerFuseTag()
        }, 1000);

    }

    private initPrimis() {
        setTimeout(() => {
            this.logger.log("initPrimis 1000");
            if (this.advertisingService.primisEnabled) {
                this.advertisingService.loadPrimisScriptToDomElement('primis-header-mobile')
                this.advertisingService.loadPrimisScriptToDomElement('primis-middle-1')
                this.advertisingService.loadPrimisScriptToDomElement('primis-right-1')
            }
        }, 1000);

    }




    public get isOwner(): boolean {
        if (this.roomIdService.getCurrentRoomID() === this.userService.getRoomId()) {
            return true;
        } else {
            return false;
        }
    }

    openResultsDialog() {
        this.googleAnalyticsService.eventEmitter('openResultsDialog', 'Poker Room', 'openResultsDialog Action');
        const dialogRef = this.dialog.open(ResultsDialog, {
            autoFocus: false,
            restoreFocus: false,
            width: '1000px',
            height: '1000px',
            data: {
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result) {
                this.logger.log('result: ' + result);
            } else {
                this.logger.log('Closed without result - hence canceled: ');
            }
        });
    }
    public isProduction() {
        return environment.production;

    }
}
