
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { EstimateService } from '../poker-room/estimate.service';
import { AuthService } from '../user/auth.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from './responsive.service';

//declare const window: any;
declare global {
    interface Window {
        fusetag: any;
        enableFooterSticky: boolean
    }
}
const fuseIdsMobile = [
    '22851570040',
    '22851418830',
];

const fuseIdsDesktop = [
    '22851570049',
    '22851570043',
    '22851570046',
    '22851569437',
];

const fuseScriptUrl = "https://cdn.fuseplatform.net/publift/tags/2/2981/fuse.js"
const fuseScriptStaging = `// Fuse script for staging only
window.googletag = window.googletag || { cmd: [] };
googletag.cmd?.push(function () {
googletag.pubads().set("page_url", "scrumpoker-online.org");
});`

const primisScriptUrl = 'https://live.primis.tech/live/liveView.php?s=118424&schain=1.0,1!publift.com,[01GJ4EMKMTHPC2HDAQA9BPR4ZX],1'
@Injectable()
export class FuseService {
    _scriptsLoaded = false;
    constructor(
        private authService: AuthService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public estimateService: EstimateService,


    ) {
    }

    public loadScripts() {
        this.setStickyFooter(false); // off when starting , enable afater entering a room only
        if (!this._scriptsLoaded) { // make sure script loaded only once
            this._scriptsLoaded = true;
            if (this.fuseEnabled) {
                this.logger.log("Fuse :loadScript")
                this.loadScript(fuseScriptUrl)
                if (!environment.production) {
                    this.loadFuseScriptForStaging()
                }
            }
            if (this.primisEnabled) {
                this.logger.warn("primis :loadScript")
                this.loadScriptToBody(primisScriptUrl)
            }
        }
    }

    get fuseEnabled() {
        return environment.enableFuse && !this.authService.hasPremium() && !this.estimateService.ownerHasPremium
    }

    get primisEnabled() {
        return environment.enablePrimis && !this.authService.hasPremium() && !this.estimateService.ownerHasPremium
    }

    private loadScript(scriptUrl: string) {
        let node = document.createElement('script');
        node.type = 'text/javascript';
        node.async = true;
        node.src = scriptUrl;
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    private loadScriptToBody(scriptUrl: string) {
        let node = document.createElement('script');
        node.type = 'text/javascript';
        node.async = true;
        node.src = scriptUrl;
        document.getElementsByTagName('body')[0].appendChild(node);
    }


    private loadFuseScriptForStaging() {
        let node = document.createElement('script');
        node.type = 'text/javascript';
        node.text = fuseScriptStaging;
        document.getElementsByTagName('head')[0].appendChild(node);
    }


    /**
     *You can control where and when the sticky footer becomes active through the script below. If window.enableFooterSticky = true, then the ad unit is active, if false, then the ad unit is not active.
    Through this condition you can control the sticky ad unit to be active on all non-premium pages. For premium users, you can exclude the script.
    <script type="text/javascript" id="publift-footer-sticky">window.enableFooterSticky = true;</script>
    Because the site is SPA, the script needs to execute before registerZone() and pageInit().
     */
    private setStickyFooter(stickyFooter: boolean) {

        window.enableFooterSticky = stickyFooter;

    }

    get fuseIds() {
        let fuseIds: string[];
        if (this.responsiveService.isScreenSmall()) {
            fuseIds = fuseIdsMobile
        } else {
            fuseIds = fuseIdsDesktop
        }
        return fuseIds
    }

    public initAndRegister() {
        const fusetag = window.fusetag || (window.fusetag = { que: [] });
        const fuseIds = this.fuseIds
        if (this.fuseEnabled) {
            this.setStickyFooter(true);
            fusetag.que.push(function () {
                fusetag.pageInit({
                    // Defer the first auction until FuseIds are registered
                    blockingFuseIds: fuseIds,
                });
            });
            fuseIds.forEach(id => {
                fusetag.que.push(function () {
                    fusetag.registerZone(id)
                });
            })
        } else {
            this.logger.log('setStickyFooter(false)')
            this.setStickyFooter(false);
            if (fusetag) {
                fusetag.pageInit()
            }

        }

    }

}
