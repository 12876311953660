import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EssSoftwareUpdateService } from 'ngx-essentia';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ConnectionStatusService } from './common/connection-status.service';
import { FuseService } from './common/fuse.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from './common/responsive.service';
import { SEOService } from './common/seo.service';
import { AuthService } from './user/auth.service';
import { PresenceService } from './user/online-presence.service';
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'Scrum Poker';
    isBlog = false;
    noBlogClass = '';
    public environment = environment;
    appContainerClass = 'app-container';
    //      this.appContainerClass = "app-container-for-blog"
    pageContainerClass = 'page-container';
    // pageContainerClass = "page-container-for-blog"

    constructor(
        public authService: AuthService,
        public responsiveService: ResponsiveService,
        private router: Router,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private seoService: SEOService,
        private connectionStatusService: ConnectionStatusService,
        public presence: PresenceService,
        private logger: LoggerService,
        public swUpdate: EssSoftwareUpdateService,
        private fuseService: FuseService,
        // Important injection to make sure the cookie banner is shown.
    ) { }
    ngOnInit() {
        this.loadAdsenseScript();
        this.fuseService.loadScripts();
        this.swUpdate.setTimeTrigger();
        this.logger.warn('Version number', environment.appVersion);
        this.responsiveService.setBreakpointObserver();
        this.setRouterSEOService();
        this.setGoogleAnalytics();
        this.subRouterEventsForBlog();
        this.connectionStatusService.isConnected();
    }


    private loadAdsenseScript() {
        if (environment.enableAdsense && !this.authService.hasPremium()) {
            this.logger.warn('Adsense :loadScript');
            let node = document.createElement('script'); // creates the script tag
            node.type = 'text/javascript'; // set the script type
            node.async = true; // makes script run asynchronously
            node.src =
                'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'; // sets the source (insert url in between quotes)
            // append to head of document
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    private setGoogleAnalytics() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', environment.google_analytics_key, {
                    page_path: this.seoService.locale + event.urlAfterRedirects,
                });
            }
        });
    }
    private subRouterEventsForBlog() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setBlogAttributes(event);
            }
        });
    }
    private setBlogAttributes(event) {
        if (event.urlAfterRedirects.indexOf('blog') != -1) {
            this.logger.log('BLOG!!!');
            this.isBlog = true;
            this.appContainerClass = 'app-container-for-blog';
            this.pageContainerClass = 'page-container-for-blog';
        } else {
            this.isBlog = false;
            this.appContainerClass = 'app-container';
            this.pageContainerClass = 'page-container';

            this.logger.log('NO BLOG!!!');
        }
    }

    private setRouterSEOService() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe((event) => {
                this.logger.log('setRouterSEOService eventid: ' + event['id']);
                this.seoService.handleRouteChange(event['id']);
            });
    }
}

