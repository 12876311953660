import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { RoomIdService } from './room-id.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { EstimateService } from './estimate.service';
import { UserService } from '../user/user.service';
import { IEstimate, IRoom } from './esimtate.model';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { map, filter } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { EssDialogService } from 'ngx-essentia';
import { SettingsComponent } from '../user/settings.component';
import { FuseService } from '../common/fuse.service';

//declare const window: any;
declare global {
    interface Window {
        fusetag: any;
    }
}



@Component({
    selector: 'provide-estimate',
    templateUrl: './provide-estimate.component.html',
    styleUrls: ['./provide-estimate.component.scss']
})
export class ProvideEstimateComponent implements OnInit, OnDestroy {
    @Input() isOwner: boolean = false;
    @Input() ownerHasPremium: boolean = false;
    provideEstimateForm: FormGroup;
    smallScreen: boolean;
    navEnd: Observable<NavigationEnd>;
    navSub: Subscription;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private roomIdService: RoomIdService,
        public estimateService: EstimateService,
        public userService: UserService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private dialogService: EssDialogService,
        private fuseService: FuseService
    ) {

        // oberve the mavigation to react on back and forward button and set the current room id accordingly
        this.navEnd = router.events.pipe(
            filter(evt => evt instanceof NavigationEnd)
        ) as Observable<NavigationEnd>;
    }
    ngOnInit() {
        this.navSub = this.navEnd.subscribe(evt => {
            this.logger.log('Navigation Ended!');
            this.roomIdService.setRoomId(this.route);
            this.estimateService.enterRoom(this.roomIdService.getCurrentRoomID(), this.userService.getDisplayName(), this.userService.getUid());
            this.fuseService.initAndRegister();

        });
        this.provideEstimateForm = new FormGroup({
        });
        if (this.userService.isLoggedIn()) {
            this.estimateService.enterRoom(
                this.roomIdService.getCurrentRoomID(),
                this.userService.getDisplayName(),
                this.userService.getUid()
            );
        } else {// user should be logged in at this point
            const message = 'ngOnInit: User no logged in';
            this.logger.error(message);
            throw message;
        }
    }

    ngOnDestroy() {
        this.estimateService.unsubscribe();
        this.navSub.unsubscribe();
    }


    getSelectedClass(storyPoints: string): string {
        //     this.logger.error('getSElectedClass', storyPoints, this.estimateService.userStoryPoints);
        if (storyPoints === this.estimateService.userStoryPoints) {
            return 'selected';
        }
        return '';
    }




    public async openSettings() {
        this.logger.log('openSettings: ');
        const dialogRef = this.dialogService.open(SettingsComponent, {
        });
        dialogRef.afterClosed().subscribe(async result => {
            console.log('The dialog was closed');
        });
    }

    async submitEstimate(storyPoints: string) {
        this.logger.log('submitEstimate: ', storyPoints);
        if (!this.userService.isLoggedIn()) {
            const message = 'Internal Error: Submit failed (not logged in).';
            this.uiMessage.warning(message);
            throw (message);
        }
        try {
            await this.estimateService.createEstimate(this.roomIdService.getCurrentRoomID(), this.userService.getDisplayName(), this.userService.getUid(), storyPoints);
            this.uiMessage.success('Estimation has been submitted.');
            this.router.navigate([this.roomIdService.getUrlPokerRoom() + '/' + storyPoints]);

        } catch (error) {
            this.uiMessage.error(error, 'Submit failed (createEstimate). ');
        }
    }
}
